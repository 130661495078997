/* merriweather-regular - latin */

@font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/merriweather-v20-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local('Merriweather Regular'), local('Merriweather-Regular'), url('../fonts/merriweather-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/merriweather-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/merriweather-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/merriweather-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/merriweather-v20-latin-regular.svg#Merriweather') format('svg');
    /* Legacy iOS */
}

/* merriweather-700 - latin */

@font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/merriweather-v20-latin-700.eot');
    /* IE9 Compat Modes */
    src: local('Merriweather Bold'), local('Merriweather-Bold'), url('../fonts/merriweather-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/merriweather-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/merriweather-v20-latin-700.woff') format('woff'), /* Modern Browsers */
    url('../fonts/merriweather-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/merriweather-v20-latin-700.svg#Merriweather') format('svg');
    /* Legacy iOS */
}